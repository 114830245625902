import Link from 'next/link'
import type { NextPage } from 'next'
import { useEffect } from 'react'
import { useBoundStore } from 'stores/boundStore'

const index: NextPage = () => {
  const saveLoginOption = useBoundStore((state) => state.saveLoginOption)

  useEffect(() => {
    const loginOption = sessionStorage.getItem('login_option')
    if (!loginOption || typeof loginOption !== 'string') {
      return
    }
    saveLoginOption(loginOption)
  }, [])

  return (
    <main className="bg-white w-screen h-screen grid">
      <div
        className="p-8 rounded-lg m-auto"
        style={{
          boxShadow:
            '0px 4px 8px 0px rgba(33,33,33,.1), 0px 6px 12px 0px rgba(33,33,33,.15)',
          maxWidth: '500px',
          borderRadius: '2em',
        }}
      >
        <img
          style={{ maxWidth: '200px' }}
          src="https://app.tomocode.com/logo.png"
        />
        <h1 className="text-2xl font-bold">Welcome to TomoCode!</h1>
        <p className="pb-4">
          学びを始めるには、Discordアカウントが必要です。Discordアカウントをお持ちでない方は「新規登録」を、お持ちの方は「ログイン」を押してください。
        </p>

        <div className=" flex justify-end">
          <Link href={`https://discord.com/register`}>
            <button
              className="transition duration-300 ease-in-out
                    buttonInit hover:bg-blue-dark hover:text-white
                    p-4 font-bold ml-2"
              style={{
                border: '2px solid #497ce3',
                borderRadius: '2em',
              }}
            >
              新規登録
            </button>
          </Link>
          <Link href={`${process.env.NEXT_PUBLIC_API_URL}/login`}>
            <button
              className="transition duration-300 ease-in-out
                    buttonInit hover:bg-blue-dark hover:text-white
                    p-4 font-bold ml-2"
              style={{
                border: '2px solid #497ce3',
                borderRadius: '2em',
              }}
            >
              ログイン
            </button>
          </Link>
        </div>
      </div>
    </main>
  )
}

export default index
